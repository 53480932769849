import React from "react"
import * as styles from "./MainConceptArtPageSection.module.scss"

export default function MainConceptArtPageSection({ backgroundImgPath }) {
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImgPath})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "auto 100vh",
  }

  return <div className={styles.container} style={backgroundStyle}></div>
}
