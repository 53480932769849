import React from "react"
import MainConceptArtPageSection from "../components/MainConceptArtPageSection/MainConceptArtPageSection"
import MainLayout from "../layouts/MainLayout"

export default function Home() {
  return (
    <MainLayout>
      <MainConceptArtPageSection backgroundImgPath="/bg/main_concept_art_1.png"></MainConceptArtPageSection>
      <MainConceptArtPageSection backgroundImgPath="/bg/main_concept_art_2.png"></MainConceptArtPageSection>
      <MainConceptArtPageSection backgroundImgPath="/bg/main_concept_art_3.png"></MainConceptArtPageSection>
    </MainLayout>
  )
}
